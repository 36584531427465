:root {
	--csb-ck-color-text: 46, 46, 46;
	--csb-ck-color-text2: 17, 17, 17;
	--csb-ck-color-text-subtle: 153, 153, 153;

	--csb-ck-color-bg: 255, 255, 255;
	--csb-ck-color-bg1: 247, 247, 247;
}

:root.dark {
	--csb-ck-color-text: 255, 255, 255;
	--csb-ck-color-text2: 226, 226, 226;
	--csb-ck-color-text-subtle: 153, 153, 153;

	--csb-ck-color-bg: 23, 23, 23;
	--csb-ck-color-bg1: 36, 36, 36;
}

/* @crossbell/ui colors */

:root {
	--csb-ui-loading-overlay-bg: var(--csb-ck-color-bg1);
	--csb-ui-loading-overlay-color: var(--csb-ck-color-text);
}

/* Legacy Colors */

:root {
	--color-153_153_153: 153, 153, 153;
	--color-117_127_141: 117, 127, 141;
	--color-250_250_250: 250, 250, 250;
	--color-255_255_255: 255, 255, 255;
	--color-246_197_73: 246, 197, 73;
	--color-106_217_145: 106, 217, 145;
	--color-46_46_46: 46, 46, 46;
	--color-245_245_245: 245, 245, 245;
	--color-8_33_53: 8, 33, 53;
	--color-230_80_64: 230, 80, 64;
	--color-91_137_247: 91, 137, 247;
	--color-150_136_242: 150, 136, 242;
	--color-246_247_249: 246, 247, 249;
	--color-0_0_0: 0, 0, 0;
	--color-255_183_77: 255, 183, 77;
	--color-217_217_217: 217, 217, 217;
	--color-225_232_247: 225, 232, 247;
	--color-109_76_65: 109, 76, 65;
	--color-251_192_45: 251, 192, 45;
	--color-69_90_100: 69, 90, 100;
	--color-0_82_255: 0, 82, 255;
	--color-226_118_27: 226, 118, 27;
	--color-228_118_27: 228, 118, 27;
	--color-215_193_179: 215, 193, 179;
	--color-35_52_71: 35, 52, 71;
	--color-205_97_22: 205, 97, 22;
	--color-228_117_31: 228, 117, 31;
	--color-246_133_27: 246, 133, 27;
	--color-192_173_158: 192, 173, 158;
	--color-22_22_22: 22, 22, 22;
	--color-118_61_22: 118, 61, 22;
	--color-255_85_0: 255, 85, 0;
	--color-255_32_0: 255, 32, 0;
	--color-255_69_42: 255, 69, 42;
	--color-28_27_31: 28, 27, 31;
	--color-250_252_255: 250, 252, 255;
	--color-229_77_37: 229, 77, 37;
	--color-255_160_0: 255, 160, 0;
	--color-211_47_47: 211, 47, 47;
	--color-38_198_218: 38, 198, 218;
	--color-189_189_189: 189, 189, 189;
	--color-55_55_55: 55, 55, 55;
	--color-60_60_60: 60, 60, 60;
	--color-59_153_252: 59, 153, 252;
	--color-17_196_209: 17, 196, 209;
	--color-0_98_173: 0, 98, 173;
	--color-41_98_239: 41, 98, 239;
	--color-37_92_229: 37, 92, 229;
	--color-151_157_164: 151, 157, 164;
	--color-70_106_191: 70, 106, 191;
	--color-54_82_149: 54, 82, 149;
	--color-88_101_242: 88, 101, 242;
	--color-29_155_240: 29, 155, 240;
	--color-80_192_123: 80, 192, 123;
	--color-165_169_173: 165, 169, 173;
	--color-212_223_230: 212, 223, 230;
	--color-198_202_205: 198, 202, 205;
	--color-189_186_196: 189, 186, 196;
	--color-147_156_161: 147, 156, 161;
	--color-99_102_241: 99, 102, 241;
	--color-56_151_251: 56, 151, 251;
	--color-37_41_46: 37, 41, 46;
	--color-247_246_248: 247, 246, 248;
	--color-231_231_231: 231, 231, 231;
	--color-17_17_17: 17, 17, 17;
	--color-47_47_47: 47, 47, 47;
	--color-46_125_50: 46, 125, 50;
	--color-115_116_121: 115, 116, 121;
	--color-38_38_38: 38, 38, 38;
	--color-242_76_100: 242, 76, 100;
	--color-113_138_200: 113, 138, 200;
	--color-228_160_128: 228, 160, 128;
	--color-246_130_147: 246, 130, 147;
	--color-156_173_217: 156, 173, 217;
	--color-236_189_166: 236, 189, 166;
	--color-135_142_149: 135, 142, 149;
	--color-207_212_217: 207, 212, 217;
	--color-70_70_70: 70, 70, 70;
	--color-120_144_156: 120, 144, 156;
	--color-169_170_172: 169, 170, 172;
	--color-26_136_248: 26, 136, 248;
	--color-169_170_171: 169, 170, 171;
	--color-104_119_146: 104, 119, 146;
	--color-94_100_115: 94, 100, 115;
	--color-136_136_136: 136, 136, 136;
}

@font-face {
font-family: '__snPro_c8d43d';
src: url(/_next/static/media/84ea885024ef7a76-s.woff2) format('woff2');
font-display: swap;
font-weight: 100;
font-style: normal;
}

@font-face {
font-family: '__snPro_c8d43d';
src: url(/_next/static/media/d6f3140c6b683a98-s.woff2) format('woff2');
font-display: swap;
font-weight: 100;
font-style: italic;
}

@font-face {
font-family: '__snPro_c8d43d';
src: url(/_next/static/media/6304aa6ad6d61483-s.woff2) format('woff2');
font-display: swap;
font-weight: 300;
font-style: normal;
}

@font-face {
font-family: '__snPro_c8d43d';
src: url(/_next/static/media/490f25fe8bc9d3b6-s.woff2) format('woff2');
font-display: swap;
font-weight: 300;
font-style: italic;
}

@font-face {
font-family: '__snPro_c8d43d';
src: url(/_next/static/media/1c12530ed5fdfec7-s.woff2) format('woff2');
font-display: swap;
font-weight: 400;
font-style: normal;
}

@font-face {
font-family: '__snPro_c8d43d';
src: url(/_next/static/media/734f34ab972582f6-s.woff2) format('woff2');
font-display: swap;
font-weight: 400;
font-style: italic;
}

@font-face {
font-family: '__snPro_c8d43d';
src: url(/_next/static/media/42646bac552f87e8-s.woff2) format('woff2');
font-display: swap;
font-weight: 500;
font-style: normal;
}

@font-face {
font-family: '__snPro_c8d43d';
src: url(/_next/static/media/f2a7be8dfb2888f6-s.woff2) format('woff2');
font-display: swap;
font-weight: 500;
font-style: italic;
}

@font-face {
font-family: '__snPro_c8d43d';
src: url(/_next/static/media/4bb954cd0e0dfc71-s.woff2) format('woff2');
font-display: swap;
font-weight: 600;
font-style: normal;
}

@font-face {
font-family: '__snPro_c8d43d';
src: url(/_next/static/media/13ef0a999dcac89f-s.woff2) format('woff2');
font-display: swap;
font-weight: 600;
font-style: italic;
}

@font-face {
font-family: '__snPro_c8d43d';
src: url(/_next/static/media/77db523a2e44171b-s.woff2) format('woff2');
font-display: swap;
font-weight: 700;
font-style: normal;
}

@font-face {
font-family: '__snPro_c8d43d';
src: url(/_next/static/media/a77f65cdc0f49469-s.woff2) format('woff2');
font-display: swap;
font-weight: 700;
font-style: italic;
}@font-face {font-family: '__snPro_Fallback_c8d43d';src: local("Arial");ascent-override: 91.45%;descent-override: 25.40%;line-gap-override: 0.00%;size-adjust: 99.62%
}.__className_c8d43d {font-family: '__snPro_c8d43d', '__snPro_Fallback_c8d43d'
}.__variable_c8d43d {--font-fans: '__snPro_c8d43d', '__snPro_Fallback_c8d43d'
}

